import { createContext } from "react"

interface AuthContext{
    user:User|null
    loading:boolean
    hasAccountSwitcherToken:boolean
    signIn:(credentials:UserCredentials)=>Promise<{user:User|null,error:string|null,originalError?:Record<string,any>|null,status?:any}>
    signOut:()=>void
    setUser:(user:User|null)=>void
    /**Available for system admin. When no email is provided, switch to the default user */
    switchToAccount:(email?:string)=>void
  }

export const AuthContext=createContext<AuthContext>({
  user: null,
  loading: true,
  hasAccountSwitcherToken:false,
  setUser(user: User | null) { },
 async signIn(credentials: UserCredentials){return Promise.resolve({user:null as User|null,error:null as string|null,originalError:null,status:null})},
  signOut() { },
  switchToAccount(email?:string){}
})