import { useAuthContext } from "Data/auth/hooks"
import { PERMISSIONS } from "Tools/permissions"
import { useMemo } from "react"


export const getFullName = ({ first_name = '', last_name = '', email }: User = {} as User) => first_name && `${first_name} ${last_name}` || email || ''


export const getNameInitials = ({ first_name = '', last_name = '' }: User = {} as User) => first_name[0] + last_name[0]

export const hasPermissions = (user = null as User | null, permissions: string[]|string[][] = []) => {
    return user ? (permissions.length ? permissions.flat().every(p => user.user_permissions.includes(p)) : true)
        : false
}

export const usePermissions=(permissions: string[]|string[][] = [])=>{
    let {user}=useAuthContext()
    let has=useMemo(()=>hasPermissions(user,permissions),[])
    return has
}
export const useAnyPermissions=(permissions: string[] = [])=>{
    let {user}=useAuthContext()
    let has=useMemo(()=>hasAnyPermissions(user!,permissions),[])
    return has
}

export const useAdminPermissions=()=>{
    let isAdmin=useAnyPermissions([
        ...PERMISSIONS.user.ADMIN_ACTIVITY,...PERMISSIONS.user.ADMIN_FROST,
        ...PERMISSIONS.user.ADMIN_SEEDCARE,...PERMISSIONS.user.ADMIN_EQUIPMENT,
    ])
    return isAdmin
}
export const useGenericPermission=(module:'Seedcare'|'FROST'|'Resistance Sampling'|'Digital Equipment')=>{
    const {user:u}=PERMISSIONS
    const {user}=useAuthContext()
    
    switch (module){
        case 'Seedcare':
            return hasPermissions(user!,u.SEEDCARE_GENERIC) && !hasAnyPermissions(user!,[u.RTM,u.SEEDCARE_COUNTRY_LEAD,u.FS,u.ADMIN_SEEDCARE,u.CRO,u.DLO,u.SEEDCARE_RTM_SUPPORT])
        default:
            throw Error('Not implemented')
    }
}

export const hasAnyPermissions = (user = {} as User, permissions: string[]|string[][] = []) => {
    return permissions.length ? permissions.flat().some(p => user.user_permissions.includes(p)) : true
}
